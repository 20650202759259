import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { Title } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';

@Component({
  selector: 'app-new-ticket',
  standalone: true,
  imports: [ButtonModule, CommonModule, CardModule],
  templateUrl: './new-ticket.component.html',
  styleUrl: './new-ticket.component.scss',
})
export class NewTicketComponent {

  constructor(private titleService: Title) {
  }

  showTopContainer: boolean = true;
  showBottomContainer: boolean = false;
  selectedCurrency: string = 'USD';
  selectedProduct: string = '';
  isOutrightSelected: boolean = true;
  isCarrySelected: boolean = false;
  isOnHold: boolean = false;
  isRiskReducing: boolean = false;
  isMatching: boolean = false;
  isFxConversion: boolean = false;

  ngOnInit() {
    this.titleService.setTitle('New Ticket');
  }

  toggleSelectedContract(contract: string) {
    if (contract === 'outright') {
      this.isOutrightSelected = true;
      this.isCarrySelected = false;
    } else {
      this.isOutrightSelected = false;
      this.isCarrySelected = true;
    }

    this.checkTicketContainer();
  }

  toggleOnHold(isOnHold: boolean) {
    if (isOnHold) {
      this.isOnHold = true;
    } else {
      this.isOnHold = false;
    }

    this.checkTicketContainer();
  }

  toggleRiskReducing(isRiskReducing: boolean) {
    if (isRiskReducing) {
      this.isRiskReducing = true;
    } else {
      this.isRiskReducing = false;
    }

    this.checkTicketContainer();
  }

  toggleMatching(isMatching: boolean) {
    if (isMatching) {
      this.isMatching = true;
    } else {
      this.isMatching = false;
    }

    this.checkTicketContainer();
  }

  toggleFxConversion(isFxConversion: boolean) {
    if (isFxConversion) {
      this.isFxConversion = true;
    } else {
      this.isFxConversion = false;
    }

    this.checkTicketContainer();
  }

  toggleSelectedCurrency(currency: string) {
    this.selectedCurrency = currency;
    this.checkTicketContainer();
  }

  toggleSelectedProduct(product: string) {
    this.selectedProduct = product;
    this.checkTicketContainer();
  }

  checkTicketContainer() {
    if (this.selectedCurrency && this.selectedProduct) {
      setTimeout(() => {
        this.showTopContainer = false;
        this.showBottomContainer = true;
      }, 500);
  
      document.getElementById('TicketTopContainer')?.classList.toggle('show');
      document.getElementById('TicketBottomContainer')?.classList.toggle('show');
    }
  }

  backToTopContainer() {
    setTimeout(() => {
      this.showTopContainer = true;
      this.showBottomContainer = false;
    }, 500);

    document.getElementById('TicketTopContainer')?.classList.toggle('show');
    document.getElementById('TicketBottomContainer')?.classList.toggle('show');
  }
}
