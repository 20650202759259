<div *ngIf="showTopContainer" class="grid card show" id="TicketTopContainer">
    <div class="col-6" style="text-align:right">
        <button pButton label="Outright" class="p-button-lg p-button-secondary shadow-6 p-4" 
                [ngClass]="{'selectedButton': isOutrightSelected}" (click)="toggleSelectedContract('outright')">
        </button>
    </div>
    <div class="col-6" style="text-align:left">
        <button pButton label="Carry" class="p-button-lg p-button-secondary shadow-6 p-4" 
                [ngClass]="{'selectedButton': isCarrySelected}" (click)="toggleSelectedContract('carry')">
        </button>
    </div>

    <div class="col-12 pt-0 pb-0" style="text-align:center">
        <button pButton label="USD" (click)="toggleSelectedCurrency('USD')" [ngClass]="{'selectedButton': selectedCurrency == 'USD'}" class="p-button-lg p-button-secondary shadow-6 m-4"></button>
        <button pButton label="JPY" (click)="toggleSelectedCurrency('JPY')" [ngClass]="{'selectedButton': selectedCurrency == 'JPY'}" class="p-button-lg p-button-secondary shadow-6 m-4"></button>
        <button pButton label="EUR" (click)="toggleSelectedCurrency('EUR')" [ngClass]="{'selectedButton': selectedCurrency == 'EUR'}" class="p-button-lg p-button-secondary shadow-6 m-4"></button>
        <button pButton label="GBP" (click)="toggleSelectedCurrency('GBP')" [ngClass]="{'selectedButton': selectedCurrency == 'GBP'}" class="p-button-lg p-button-secondary shadow-6 m-4"></button>
        <button pButton label="CHF" (click)="toggleSelectedCurrency('CHF')" [ngClass]="{'selectedButton': selectedCurrency == 'CHF'}" class="p-button-lg p-button-secondary shadow-6 m-4"></button>
        <button pButton label="NOK" (click)="toggleSelectedCurrency('NOK')" [ngClass]="{'selectedButton': selectedCurrency == 'NOK'}" class="p-button-lg p-button-secondary shadow-6 m-4"></button>
        <button pButton label="CNY" (click)="toggleSelectedCurrency('CNY')" [ngClass]="{'selectedButton': selectedCurrency == 'CNY'}" class="p-button-lg p-button-secondary shadow-6 m-4"></button>
    </div>

    <div class="col-12 pt-0 pb-0" style="text-align: center">
        <button pButton label="FX Conv" [ngClass]="{'selectedButton': isFxConversion}" (click)="toggleFxConversion(true)" icon="pi pi-check" class="p-button-sm p-button-secondary p-button-success shadow-6 m-1"></button>
        <button pButton label="FX Conv" [ngClass]="{'selectedButton': !isFxConversion}" (click)="toggleFxConversion(false)" icon="pi pi-ban" class="p-button-sm p-button-secondary p-button-danger shadow-6 m-1 mr-6 selectedButton"></button>

        <button pButton label="On Hold" [ngClass]="{'selectedButton': isOnHold}" (click)="toggleOnHold(true)" icon="pi pi-check" class="p-button-sm p-button-secondary p-button-success shadow-6 m-1"></button>
        <button pButton label="On Hold" [ngClass]="{'selectedButton': !isOnHold}" (click)="toggleOnHold(false)" icon="pi pi-ban" class="p-button-sm p-button-secondary p-button-danger shadow-6 m-1 mr-6 selectedButton"></button>

        <button pButton label="Rsk Rduc" [ngClass]="{'selectedButton': isRiskReducing}" (click)="toggleRiskReducing(true)" icon="pi pi-check" class="p-button-sm p-button-secondary p-button-success shadow-6 m-1"></button>
        <button pButton label="Rsk Rduc" [ngClass]="{'selectedButton': !isRiskReducing}" (click)="toggleRiskReducing(false)" icon="pi pi-ban" class="p-button-sm p-button-secondary p-button-danger shadow-6 m-1 mr-6 selectedButton"></button>

        <button pButton label="Matching" [ngClass]="{'selectedButton': isMatching}" (click)="toggleMatching(true)" icon="pi pi-check" class="p-button-sm p-button-secondary p-button-success shadow-6 m-1"></button>
        <button pButton label="Matching" [ngClass]="{'selectedButton': !isMatching}" (click)="toggleMatching(false)" icon="pi pi-ban" class="p-button-sm p-button-secondary p-button-danger shadow-6 m-1 selectedButton"></button>
    </div>

    <div class="col-12 pt-0 pb-0" style="text-align: center">
        <button pButton label="AA-LME" (click)="toggleSelectedProduct('AA-LME')" [ngClass]="{'selectedButton': selectedProduct == 'AA-LME'}" class="p-button-lg shadow-6 m-4" style="color: black; background-color: rgb(145, 218, 189); border: 1px solid rgb(145, 218, 189)"></button>
        <button pButton label="AL-LME" (click)="toggleSelectedProduct('AL-LME')" [ngClass]="{'selectedButton': selectedProduct == 'AL-LME'}" class="p-button-lg shadow-6 m-4" style="color: black; background-color: rgb(211, 211, 211); border: 1px solid rgb(211, 211, 211)"></button>
        <button pButton label="CU-COMEX" (click)="toggleSelectedProduct('CU-COMEX')" [ngClass]="{'selectedButton': selectedProduct == 'CU-COMEX'}" class="p-button-lg shadow-6 m-4" style="color: black; background-color: rgb(204, 229, 255); border: 1px solid rgb(204, 229, 255)"></button>
        <button pButton label="CU-LME" (click)="toggleSelectedProduct('CU-LME')" [ngClass]="{'selectedButton': selectedProduct == 'CU-LME'}" class="p-button-lg shadow-6 m-4" style="color: black; background-color: rgb(255, 182, 193); border: 1px solid rgb(255, 182, 193)"></button>
        <button pButton label="NA-LME" (click)="toggleSelectedProduct('NA-LME')" [ngClass]="{'selectedButton': selectedProduct == 'NA-LME'}" class="p-button-lg shadow-6 m-4" style="color: black; background-color: rgb(177, 156, 217); border: 1px solid rgb(177, 156, 217)"></button>
        <button pButton label="NI-LME" (click)="toggleSelectedProduct('NI-LME')" [ngClass]="{'selectedButton': selectedProduct == 'NI-LME'}" class="p-button-lg shadow-6 m-4" style="color: black; background-color: rgb(232, 184, 34); border: 1px solid rgb(232, 184, 34)"></button>
        <button pButton label="PB-LME" (click)="toggleSelectedProduct('PB-LME')" [ngClass]="{'selectedButton': selectedProduct == 'PB-LME'}" class="p-button-lg shadow-6 m-4" style="color: black; background-color: rgb(125, 195, 255); border: 1px solid rgb(125, 195, 255)"></button>
        <button pButton label="SN-LME" (click)="toggleSelectedProduct('SN-LME')" [ngClass]="{'selectedButton': selectedProduct == 'SN-LME'}" class="p-button-lg shadow-6 m-4" style="color: black; background-color: rgb(144, 238, 144); border: 1px solid rgb(144, 238, 144)"></button>
        <button pButton label="ZN-LME" (click)="toggleSelectedProduct('ZN-LME')" [ngClass]="{'selectedButton': selectedProduct == 'ZN-LME'}" class="p-button-lg shadow-6 m-4" style="color: black; background-color: rgb(255, 255, 102); border: 1px solid rgb(255, 255, 102)"></button>
    </div>
</div>

<div *ngIf="showBottomContainer" class="grid card show" id="TicketBottomContainer">
    <div class="col-5">

    </div>
    <div class="col-2" style="text-align: center">
        <div class="card shadow-8 pl-0 pr-0 pb-0" (click)="backToTopContainer()" style="cursor: pointer">
            <h6>{{isOutrightSelected ? 'Outright' : 'Carry'}} ({{selectedCurrency}})</h6>
            <h4 class="pb-4 pt-4" style="background-color:rgb(204, 229, 255); color: black">CU-COMEX</h4>
        </div>
    </div>
    <div class="col-5">

    </div>
</div>