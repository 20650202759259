import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-new-trade',
  standalone: true,
  imports: [
    ButtonModule
  ],
  templateUrl: './new-trade.component.html',
  styleUrl: './new-trade.component.scss'
})
export class NewTradeComponent {

}
