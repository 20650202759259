import { OnInit } from '@angular/core';
import { Component } from '@angular/core';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
    model: any[] = [];

    ngOnInit() {
        this.model = [
            {
                label: 'Accounts',
                icon: 'pi pi-fw pi-users',
                items: [
                    {
                        label: 'User Profiles',
                        icon: 'pi pi-fw pi-user',
                        routerLink: ['/accounts/user-profiles'],
                    }
                ],
            },
            {
                label: 'Ref Data',
                icon: 'pi pi-home',
                items: [
                    {
                        label: 'Blotter',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/ref-data/blotter'],
                    },
                    {
                        label: 'Calendar',
                        icon: 'pi pi-fw pi-calendar',
                        items:[{
                            label: 'Calendars',
                            icon: 'pi pi-fw pi-calendar',
                            routerLink: ['/ref-data/calendar-calendars']
                            }, 
                            {
                            label: 'Override Maturity Dates',
                            icon: 'pi pi-fw pi-calendar',
                            routerLink: ['/ref-data/calendar-override-maturity-dates'] 
                            }
                        ]
                    },
                    {
                        label: 'Entities',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/ref-data/entity'],
                    },
                    {
                        label: 'Locations',
                        icon: 'pi pi-fw pi-globe',
                        routerLink: ['/ref-data/location'],
                    },
                    {
                        label: 'Orders',
                        icon: 'pi pi-fw pi-globe',
                        routerLink: ['/ref-data/orders-refdata-screen'],
                    },
                    {
                        label: 'RFQ',
                        icon: 'pi pi-fw pi-briefcase',
                        items: [
                            {
                                label: 'Salesforce Clients',
                                icon: 'pi pi-fw pi-briefcase',
                                routerLink: ['/ref-data/rfq-salesforce-clients'],
                            },
                            {
                                label: 'Salesforce Users',
                                icon: 'pi pi-fw pi-briefcase',
                                routerLink: ['/ref-data/rfq-salesforce-users'],
                            },
                        ]
                    },
                    {
                        label: 'Traders',
                        icon: 'pi pi-fw pi-user',
                        routerLink: ['/ref-data/trader'],
                    },
                    
                ],
            },
            {
                label: 'Recent Orders/Trades',
                icon: 'pi pi-th-large',
                routerLink: ['/'],
            },
            {
                label: 'Trades',
                icon: 'pi pi-fw pi-file',
                items: [
                    {
                        label: 'View Trades',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/trade/view'],
                    },
                    {
                        label: 'New Trade',
                        icon: 'pi pi-fw pi-check-square',
                        routerLink: ['/trade/new'],
                    },
                    {
                        label: 'Trade Integration',
                        icon: 'pi pi-fw pi-bookmark',
                        routerLink: ['/trade/integration'],
                    },
                    {
                        label: 'Refresh Trades Cache',
                        icon: 'pi pi-fw pi-exclamation-circle',
                        routerLink: ['/trade/cache'],
                    },
                ],
            },
            {
                label: 'Orders',
                icon: 'pi pi-fw pi-file-edit',
                items: [
                    {
                        label: 'View Orders',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/order/view'],
                    },
                    {
                        label: 'New Order',
                        icon: 'pi pi-fw pi-check-square',
                        url: ['https://www.primefaces.org/primeblocks-ng'],
                        target: '_blank',
                    },
                ],
            },
            {
                label: 'Tickets',
                icon: 'pi pi-fw pi-tag',
                items: [
                    {
                        label: 'Search Tickets',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/blocks'],
                    },
                    {
                        label: 'New Ticket',
                        icon: 'pi pi-fw pi-check-square',
                        routerLink: ['/ticket/new'],
                    },
                ],
            },
            {
                label: 'Blotter',
                icon: 'pi pi-fw pi-inbox',
                items: [
                    {
                        label: 'GSAH',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/blocks'],
                    },
                    {
                        label: 'GSARB-CME',
                        icon: 'pi pi-fw pi-check-square',
                        url: ['https://www.primefaces.org/primeblocks-ng'],
                        target: '_blank',
                    },
                    {
                        label: 'GSCA',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/blocks'],
                    },
                    {
                        label: 'GSNI',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/blocks'],
                    },
                    {
                        label: 'GSPB',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/blocks'],
                    },
                    {
                        label: 'GSSN',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/blocks'],
                    },
                    {
                        label: 'GSPB',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/blocks'],
                    },
                    {
                        label: 'MMAA',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/blocks'],
                    },
                    {
                        label: 'MMAH',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/blocks'],
                    },
                    {
                        label: 'MMCA',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/blocks'],
                    },
                    {
                        label: 'MMNI',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/blocks'],
                    },
                    {
                        label: 'MMPB',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/blocks'],
                    },
                    {
                        label: 'MMSN',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/blocks'],
                    },
                    {
                        label: 'Copper USARB',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/blocks'],
                    },
                    {
                        label: 'Hand Off',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/blocks'],
                    },
                ],
            },
            {
                label: 'RFQ',
                icon: 'pi pi-fw pi-comment',
                routerLink: ['/rfq'],
            },
            {
                label: 'FIX/RFO',
                icon: 'pi pi-fw pi-comments',
                routerLink: ['/fix'],
            },
        ];
    }
}
