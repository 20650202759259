<div class="card">
    <div class="grid">
        <div class="col-2">
            8-Mar-24
        </div>
        <div class="col-10" [innerHTML]="sanitizer.bypassSecurityTrustHtml(tradeOrder.sentence)"></div>
    </div>
</div>
<p-fieldset legend="Trade Notes">
    @for (note of tradeOrder.notes; track note) {
        {{note}}
    }
</p-fieldset>
<p-fieldset legend="Add New Note (USER)">
    <div>
        Note Category
    </div>
    <div>
        <p-dropdown [options]="noteCategories" [(ngModel)]="selectedNoteCategory" [style]="{ width: '20%' }"></p-dropdown>
    </div>
    <div class="mt-2">
        Note
    </div>
    <div>
        <textarea rows="5" cols="90" pInputTextarea [(ngModel)]="newNoteText"></textarea>
    </div>
    <div>
        <button pButton type="button" label="Save" (click)="saveNewNote()" class="p-button-sm p-button-success m-2" icon="pi pi-save"></button>
        <button pButton type="button" label="Reset Note" (click)="resetNote()" class="p-button-sm p-button-warning m-2" icon="pi pi-plus"></button>
        <button pButton type="button" label="Exit Without Saving" (click)="exitNewNote()" class="p-button-sm p-button-danger m-2" icon="pi pi-times"></button>
    </div>
</p-fieldset>