import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

    messageFromServer: any;
    subject: WebSocketSubject<unknown>;
    websocketRetries: number = 0;

    constructor(private primengConfig: PrimeNGConfig) { }

    ngOnInit(): void {
        this.primengConfig.ripple = true;
        this.subscribeToWebsocket();
    }

    ngOnDestroy() {
        this.subject.unsubscribe();
    }

    subscribeToWebsocket() {
        try {
            if (this.subject) {
                console.log('We have a subject, so unsubscribing first.');
                this.subject.unsubscribe();
            }

            //This will allow us to reset the retries back to the primary URL. So the
            //primary URL will be re-tried 3 times, then the secondary URL for 3 tries, and back and forth
            if (this.websocketRetries > 6) {
                this.websocketRetries = 0;
            }

            if (this.websocketRetries > 3) {
                console.log('Exceeded retries on the primary URL, trying the secondary URL.');
                this.subject = webSocket(environment.secondaryWebsocketUrl);
            }
            else {
                this.subject = webSocket(environment.primaryWebsocketUrl);
            }

            this.subject.subscribe({
                next: (msg) => {
                    // Called whenever there is a message from the server.
                    this.messageFromServer = JSON.stringify(msg); // This can be a string without needing stringify or it can be a data object. It just depends on how you send the data back.
                    alert(this.messageFromServer);
                }, 
                error: (err) => {
                    // Called if at any point WebSocket API signals some kind of error.
                    console.log('this.subject.subscribe had a websocket error, trying again in 1 second');
                    console.log(err);
                    this.websocketRetries++;
                    setTimeout(() => {
                        this.subscribeToWebsocket();
                    }, 1000);
                },
                complete: () => {
                    console.log('Closed'); // Called when connection is closed (for whatever reason).
                    this.subscribeToWebsocket(); // Re-connect
                },
            });
        } catch (ex) {
            this.websocketRetries++;
            console.log('There was an issue connecting to the websocket. Retrying in 2 seconds.');
            console.log(ex);
            setTimeout(() => {
                this.subscribeToWebsocket();
            }, 2000);
        }
    }
    
}
