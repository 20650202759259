<div
    class="layout-topbar"
    [ngClass]="{
        'border-bottom-none': layoutService.config().topbarTheme !== 'light'
    }"
>
    <div class="layout-topbar-start">
        <a
            #mobileMenuButton
            class="layout-menu-button lg:hidden"
            (click)="onMenuButtonClick()"
            pRipple
        >
            <i class="pi pi-bars"></i>
        </a>
        <a class="layout-topbar-logo" routerLink="/">
            <img
                [src]="
                    'assets/image/logo-' +
                    (layoutService.config().topbarTheme === 'light'
                        ? 'mom-light'
                        : 'mom-dark') +
                    '.svg'
                "
                alt="Koch Logo"
            />
        </a>
        <a
            class="lg:hidden"
            pStyleClass="@next"
            enterFromClass="hidden"
            enterActiveClass="scalein"
            leaveToClass="hidden"
            leaveActiveClass="fadeout"
            [hideOnOutsideClick]="true"
        >
        </a>
        <div class="layout-topbar-menu shadow-2 px-3 hidden" style="top: 9rem">
            <ul class="list-none p-0 m-0">
                <li class="mb-2">
                    <div class="flex gap-3 p-3 bg-primary-600">
                        <div class="flex flex-column cursor-pointer">
                            <span class="font-semibold text-white"
                                >{{firstName}} {{lastName}}</span
                            >
                            <span class="text-xs font-normal text-white"
                                >{{userEmail}}</span
                            >
                        </div>
                    </div>
                </li>
                <li class="py-3 px-4 flex gap-2 cursor-pointer">
                    <a>
                        <i class="pi pi-cog pi-star text-lg mr-3"></i>
                        <span class="font-semibold">Settings</span>
                    </a>
                </li>
                <li class="py-3 px-4 flex gap-2 cursor-pointer">
                    <a>
                        <i class="pi pi-file pi-lock text-lg mr-3"></i>
                        <span class="font-semibold">Terms of Usage</span>
                    </a>
                </li>
                <li class="py-3 px-4 flex gap-2 cursor-pointer">
                    <a>
                        <i class="pi pi-compass pi-image text-lg mr-3"></i>
                        <span class="font-semibold">Support</span>
                    </a>
                </li>
                <li class="py-3 px-4 flex gap-2 cursor-pointer">
                    <a>
                        <i class="pi pi-power-off pi-image text-lg mr-3"></i>
                        <span class="font-semibold">Logout</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="layout-topbar-end surface-border">
        <div class="layout-topbar-actions-start">
            <a
                *ngIf="!layoutService.isHorizontal()"
                #menuButton
                class="layout-menu-button hidden lg:flex"
                (click)="onMenuButtonClick()"
                pRipple
            >
                <i class="pi pi-bars"></i>
            </a>
        </div>

        <div class="layout-topbar-actions-end">
            <ul class="layout-topbar-items">
                <li>
                    <a
                        pStyleClass="@next"
                        enterFromClass="hidden"
                        enterActiveClass="scalein"
                        leaveToClass="hidden"
                        leaveActiveClass="fadeout"
                        [hideOnOutsideClick]="true"
                        pRipple
                    >
                        <i class="pi pi-cog"></i>
                    </a>
                    <div
                        class="layout-topbar-menu shadow-2 pt-0 hidden cursor-pointer"
                    >
                        <ul class="list-none p-0 m-0">
                            <li
                                class="flex justify-content-between align-items-center px-3 p-3 mb-3"
                            >
                                <h6 class="m-0 font-semibold">Settings</h6>
                            </li>
                            <li>
                                <a
                                    class="py-2 px-3 flex gap-2 align-items-center cursor-pointer"
                                >
                                    <div
                                        class="flex align-items-center justify-content-center border-1 border-circle border-transparent bg-blue-100 text-500"
                                        style="height: 30px; width: 30px"
                                    >
                                        <i
                                            class="pi pi-user text-lg text-blue-500"
                                        ></i>
                                    </div>
                                    <h6 class="m-0 font-semibold">
                                        Account Info
                                    </h6>
                                </a>
                            </li>
                            <li>
                                <a
                                    class="py-2 px-3 flex gap-2 align-items-center cursor-pointer"
                                >
                                    <div
                                        class="flex align-items-center justify-content-center border-1 border-circle border-transparent bg-cyan-100 text-500"
                                        style="height: 30px; width: 30px"
                                    >
                                        <i
                                            class="pi pi-users text-lg text-cyan-500"
                                        ></i>
                                    </div>
                                    <h6 class="m-0 font-semibold">
                                        Global Accounts
                                    </h6>
                                </a>
                            </li>
                            <li>
                                <a
                                    class="py-2 px-3 flex gap-2 align-items-center cursor-pointer"
                                >
                                    <div
                                        class="flex align-items-center justify-content-center border-1 border-circle border-transparent bg-pink-100 text-500"
                                        style="height: 30px; width: 30px"
                                    >
                                        <i
                                            class="pi pi-bell text-lg text-pink-500"
                                        ></i>
                                    </div>
                                    <h6 class="m-0 font-semibold">
                                        Notification Preferences
                                    </h6>
                                </a>
                            </li>
                            <li>
                                <a
                                    class="py-2 px-3 flex gap-2 align-items-center cursor-pointer"
                                >
                                    <div
                                        class="flex align-items-center justify-content-center border-1 border-circle border-transparent bg-blue-100 text-500"
                                        style="height: 30px; width: 30px"
                                    >
                                        <i
                                            class="pi pi-lock text-lg text-blue-500"
                                        ></i>
                                    </div>
                                    <h6 class="m-0 font-semibold">
                                        Login Settings
                                    </h6>
                                </a>
                            </li>
                            <li>
                                <a
                                    class="py-2 px-3 flex gap-2 align-items-center"
                                >
                                    <p-inputSwitch (onChange)="onDarkModeChange($event)" [(ngModel)]="darkMode"></p-inputSwitch>
                                    <h6 class="m-0 font-semibold">
                                        Dark Mode
                                    </h6>
                                </a>
                            </li>
                            <li>
                                <div class="flex align-items-center">
                                    <button
                                        icon="pi pi-minus"
                                        type="button"
                                        pButton
                                        (click)="decrementScale()"
                                        class="p-button-text p-button-rounded w-2rem h-2rem mr-2"
                                   
                                        [disabled]="scale === scales[0]"
                                    ></button>
                                    <div class="flex gap-2 align-items-center">
                                        <i
                                            class="pi pi-circle-fill text-300"
                                            *ngFor="let s of scales"
                                            [ngClass]="{ 'text-primary-500': s === scale }"
                                        ></i>
                                    </div>
                                    <button
                                        icon="pi pi-plus"
                                        type="button"
                                        pButton
                                        (click)="incrementScale()"
                                        class="p-button-text p-button-rounded w-2rem h-2rem ml-2"
                                        [disabled]="scale === scales[scales.length - 1]"
                                    ></button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="layout-topbar-profile">
                    <a
                        *ngIf="!layoutService.isMobile()"
                        pStyleClass="@next"
                        enterFromClass="hidden"
                        enterActiveClass="scalein"
                        leaveToClass="hidden"
                        leaveActiveClass="fadeout"
                        [hideOnOutsideClick]="true"
                    >
                        <div class="flex gap-2">
                            <div class="flex flex-column">
                                <span class="font-semibold">{{firstName}} {{lastName}}</span>
                                <span class="text-sm">{{userEmail}}</span>
                            </div>
                        </div>
                    </a>
                    <div class="layout-topbar-menu p-0 shadow-2 hidden">
                        <ul class="list-none p-0 m-0">
                            <li class="mb-2">
                                <div class="flex gap-3 p-3 bg-primary-600">
                                    <div
                                        class="flex flex-column cursor-pointer"
                                    >
                                        <span class="font-semibold text-white"
                                            >{{firstName}} {{lastName}}</span
                                        >
                                    </div>
                                </div>
                            </li>
                            <li class="py-3 px-4 flex gap-2 cursor-pointer">
                                <a>
                                    <i class="pi pi-cog pi-star text-lg"></i>
                                    <span class="font-semibold mx-3"
                                        >Settings</span
                                    >
                                </a>
                            </li>
                            <li class="py-3 px-4 flex gap-2 cursor-pointer">
                                <a>
                                    <i class="pi pi-file pi-lock text-lg"></i>
                                    <span class="font-semibold mx-3"
                                        >Terms of Usage</span
                                    >
                                </a>
                            </li>
                            <li class="py-3 px-4 flex gap-2 cursor-pointer">
                                <a>
                                    <i
                                        class="pi pi-compass pi-image text-lg"
                                    ></i>
                                    <span class="font-semibold mx-3"
                                        >Support</span
                                    >
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <a routerLink="/documentation" pTooltip="Documentation" tooltipPosition="bottom">
                        <i class="pi pi-info-circle"></i>
                    </a>
                </li>
                <li>
                    <a (click)="logOut()" onclick="return false;" pTooltip="Sign Out" tooltipPosition="bottom">
                        <i class="pi pi-sign-out"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
