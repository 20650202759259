<div class="layout-container" [ngClass]="containerClass">

    <app-topbar></app-topbar>
    <app-sidebar></app-sidebar>

    <div class="layout-content-wrapper">

        <div class="layout-content">
            <p-messages></p-messages>
            <p-toast key="toast"></p-toast>

            <router-outlet></router-outlet>
        </div>

    </div>

    <div *ngIf="layoutService.state.staticMenuMobileActive" class="layout-mask"></div>
</div>
