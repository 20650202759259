import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { ExpireOrders } from 'src/app/models/manage-orders';
import { TableColumnType } from 'src/app/models/table-column-type';
import { MomApiService } from 'src/app/service/mom-api.service';
import { UserService } from 'src/app/service/user.service';
import { RefDataTableComponent } from 'src/app/shared/ref-data-table/ref-data-table.component';
import { Client } from '../models/client';
import { Product } from '../models/product-setup';
import { Trader } from '../models/trader';

@Component({
  selector: 'app-manage-orders',
  standalone: true,
  imports: [TableModule,
    CommonModule,
    CheckboxModule,
    ButtonModule,
    TooltipModule,
    InputTextModule,
    DialogModule,
    InputSwitchModule,
    DropdownModule,
    FormsModule,
    RefDataTableComponent],
  templateUrl: './manage-orders.component.html',
  styleUrl: './manage-orders.component.scss'
})
export class ManageOrdersComponent {

  // filters declaration for two way binding
  product: Product;
  client: Client;
  trader: Trader;

  products: Product[] = [];
  clients: Client[] = [];
  traders: Trader[] = [];

  multiSelectLabel: string;
  loading: string[] = new Array(); // Array of loading states for async operations
  //expireOrders test data
  expireOrders: ExpireOrders[] = [];
  expireOrdersResponse: ExpireOrders[]= [];
  constructor(private titleService: Title, private messageService: MessageService, private momApiService: MomApiService, public userService: UserService, public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.multiSelectLabel = "Select All";
    this.getExpireOrders();

    this.getProducts();
    this.getClients();
    this.getTraders();
  }

  getExpireOrders() {
    this.loading.push('expireOrders');
    this.momApiService.getExpireOrders(false).subscribe({
      next: (result: any) => {
        this.expireOrders = result;
        this.expireOrdersResponse = result;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: typeof error?.error?.message === 'string' ? error.error.message : typeof error?.message === 'string' ? error.message : typeof error?.error === 'string' ? error.error : 'An unexpected error occurred' });
        this.loading = this.loading.filter(item => item !== 'expireOrders');
      },
      complete: () => {
        this.loading = this.loading.filter(item => item !== 'expireOrders');
      }
    })
  }

  getClients() {
    this.loading.push('clients');
    this.momApiService.getClients(false, true).subscribe({
      next: (response: Client[]) => {
        this.clients = response;
      },
      error: (error) => {
        console.error('Error fetching clients:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: typeof error?.error?.message === 'string' ? error.error.message : typeof error?.message === 'string' ? error.message : typeof error?.error === 'string' ? error.error : 'An unexpected error occurred' });
        this.loading = this.loading.filter(item => item !== 'clients');
      },
      complete: () => {

        this.loading = this.loading.filter(item => item !== 'clients');
      }
    });
  }

  getProducts() {
    this.loading.push('products');
    this.momApiService.getProducts(false, true).subscribe({
      next: (response: any) => {
        this.products = response;
      },
      error: () => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to load Products.' });
        this.loading = this.loading.filter(item => item !== 'products');
      },
      complete: () => {
        this.loading = this.loading.filter(item => item !== 'products');
      }
    });
  }

  getTraders() {
    this.loading.push('traders');
    this.momApiService.getTraders(false, true).subscribe({
      next: (response: Trader[]) => {
        this.traders = response;
      },
      error: (error) => {
        console.error('Error fetching traders:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: typeof error?.error?.message === 'string' ? error.error.message : typeof error?.message === 'string' ? error.message : typeof error?.error === 'string' ? error.error : 'An unexpected error occurred' });
        this.loading = this.loading.filter(item => item !== 'traders');
      },
      complete: () => {

        this.loading = this.loading.filter(item => item !== 'traders');
      }
    });
  }
 
  applyGridFilter() {
    if(!this.product && !this.trader && !this.client) {
      this.expireOrders = this.expireOrdersResponse;
      this.onSelectOrderCheck();
      return;
    }
    
    this.expireOrders = this.expireOrdersResponse.filter(order =>
      (!this.product?.displayName || order.product == this.product.displayName) &&
      (!this.client?.clientAlias || order.client == this.client.clientAlias) &&
      (!this.trader?.fullName || order.trader == this.trader.fullName)
    );
  }

  selectAllOrders() {
    const isSelecting = this.multiSelectLabel === "Select All";
    this.multiSelectLabel = isSelecting ? "Deselect All" : "Select All";
    this.expireOrders.forEach(order => order.expire = isSelecting);
  }
  
  onSelectOrderCheck() {
    this.multiSelectLabel = this.expireOrders.every(order => order.expire) ? "Deselect All" : "Select All";
  }

  cancelSelectedOrders(){
    // Implement cancel orders functionality by invkoing API & refreshing the table
  }

  expireSelectedOrders() {
    // Implement Expire orders functionality by invkoing API & refreshing the table
  }
}
