import { Component, Input, Output } from '@angular/core';
import { FieldsetModule } from 'primeng/fieldset';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { EventEmitter } from '@angular/core';


@Component({
  selector: 'app-trade-order-notes',
  standalone: true,
  imports: [FieldsetModule, InputTextareaModule, DropdownModule, CommonModule, FormsModule, ButtonModule],
  templateUrl: './trade-order-notes.component.html',
  styleUrl: './trade-order-notes.component.scss'
})

export class TradeOrderNotesComponent {
  @Input() tradeOrder: any; 
  //Have output emitter to send the updated tradeOrder back to the parent component
  @Output() closeNotes: any = new EventEmitter<any>();
  noteCategories: any[] = [];
  selectedNoteCategory: any;
  newNoteText: string = '';

  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.noteCategories = [
      { label: 'Internal', value: 1 },
      { label: 'External', value: 2 },
      { label: 'Execution Evidence', value: 3 },
    ];
  }

  saveNewNote() {
    //Add the new note to the tradeOrder object
    this.tradeOrder.notes.push(this.newNoteText);
    this.closeNotes.emit();
  }

  resetNote() {
    this.newNoteText = '';
  }

  exitNewNote() {
    this.newNoteText = '';
    this.closeNotes.emit();
  }
}
