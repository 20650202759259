import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Amplify } from 'aws-amplify';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { getCurrentUser } from 'aws-amplify/auth';

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));

try {
  configureAuth(false);
}
catch (ex) {
  configureAuth(true);
}

//Set a timeout to check the health of the cognito pool every 2 minutes
//If there is an issue we want to re-configure amplify to use the failover pool
// setInterval(async () => {
//   try {
//     console.log('checking user');
//     var user = await getCurrentUser();
//     configureAuth(true);
//   }
//   catch (ex) {
//     console.log('error checking user');
//     console.log(ex);
//     configureAuth(true);
//   }
// }, 120000);

function configureAuth(useFailover: boolean) {
  console.log('configuring auth with use failover = ' + useFailover);
  Amplify.configure({
    Auth: {
      Cognito: {
        //  Amazon Cognito User Pool ID
        userPoolId: useFailover ? environment.failoverUserPoolId : environment.userPoolId,
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolClientId: useFailover ? environment.failoverUserPoolWebClientId : environment.userPoolWebClientId,
        loginWith: {
          // OPTIONAL - Hosted UI configuration
          oauth: {
            domain: useFailover ? environment.failoverCognitoDomain : environment.cognitoDomain,
            scopes: [
              'email',
              'profile',
              'openid',
              'aws.cognito.signin.user.admin'
            ],
            redirectSignIn: [environment.redirectURL],
            redirectSignOut: [environment.redirectURL],
            responseType: 'code'
          }
        }
      }
    }
  });
}
