import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { getCurrentUser } from 'aws-amplify/auth';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      var user = await getCurrentUser();
      if (user && user.username) {
        return true;
      }
      else {
        console.log('user not signed in');
        return false;
      }
    }
    catch (ex) {
      console.log('route guard error');
      console.log(ex);
      var url = window.location.href;
      this.router.navigate(['/login'], {
          queryParams: {
            returnUrl: url
          },
          queryParamsHandling: 'merge'
        });
      return false;
    }
  }
}