import { Component, Input, Output } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { EventEmitter } from '@angular/core';
import { DialogModule } from 'primeng/dialog';

@Component({
    selector: 'app-save-new-view',
    standalone: true,
    imports: [DropdownModule, CommonModule, FormsModule, ButtonModule, CheckboxModule, SaveNewViewComponent, InputTextModule, DialogModule],
    templateUrl: './save-new-view.component.html',
    styleUrl: './save-new-view.component.scss'
  })
  

export class SaveNewViewComponent {
    @Input() newView: any; 
    @Input() savedFilters: any[];
    @Input() showSaveDialog: boolean;
    @Input() selectedFilter: any = new EventEmitter<any>();
    @Output() closeNewView: any = new EventEmitter<any>();
    @Output() saveView: any = new EventEmitter<any>();
    
    //Temp Outputs to use default view or second view
    @Output() loadDefault: any = new EventEmitter<any>();
    @Output() loadSecond: any = new EventEmitter<any>();

    globalViewName: string = '';
    alias: string | undefined = '';
    hierarchy: string | undefined = '';
    publishView: any[] = [];
    selectedPublishView: any;
    isFavorite: boolean = false;
    isPrivate: boolean = false;
    isDefaultView: boolean = false;
    
    constructor() { }

    ngOnInit() {
        //create 3 sample publish view objects
        this.publishView = [
            { label: 'Trade', value: 'Trade' },
            { label: 'Order', value: 'Order' },
            { label: 'Position', value: 'Position' }
        ];
    }

    saveNewView() {
        this.newView.globalViewName = this.globalViewName;
        this.newView.alias = this.alias;
        this.newView.hierarchy = this.hierarchy;
        this.newView.publishView = this.selectedPublishView;
        this.newView.isFavorite = this.isFavorite;
        this.newView.isPrivate = this.isPrivate;
        this.newView.isDefaultView = this.isDefaultView;
        this.saveView.emit(this.newView);
    }

    closeSaveNewView() {
        this.globalViewName = '';
        this.alias = '';
        this.hierarchy = '';
        this.selectedPublishView = '';
        this.isFavorite = false;
        this.isPrivate = false;
        this.isDefaultView = false;
        this.showSaveDialog = false;
        this.closeNewView.emit();
    }

    showNewSaveDialog() {
        this.showSaveDialog = true;
    }

    loadDefaultView() {
        this.loadDefault.emit();
    }
    
    loadSecondView() {
        this.loadSecond.emit();
    }

    setSelectedFilter(view: any) {
        this.selectedFilter = view
    }

}