import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { fetchAuthSession } from "aws-amplify/auth";
import { Observable, from, lastValueFrom } from 'rxjs';



@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // convert promise to observable using 'from' operator
    //The purpose of the auth interceptor is to add the idToken to the 
    //headers of EVERY request before it is sent to the server.
    return from(this.handle(req, next))
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    var cognitoTokens = (await fetchAuthSession()).tokens;
    let idToken = cognitoTokens?.idToken?.toString();

    req = req.clone({
        setHeaders: {
           'Authorization': `${idToken}`,
        },
      });

    return await lastValueFrom(next.handle(req));
  }
}