<div>
    <button pButton type="button" (click)="showNewSaveDialog()" class="p-button-sm p-button-success ml-3 m-2" icon="pi pi-save" label="Save New View"></button>
    <p-dropdown [options]="savedFilters" [(ngModel)]="selectedFilter" [style]="{ width: '20%' }" optionLabel="globalViewName"></p-dropdown>
    <button pButton type="button" (click)="loadDefaultView()" class="p-button-sm p-button-success m-2" icon="pi pi-save">Use Defualt View</button>
    <button pButton type="button" (click)="loadSecondView()" class="p-button-sm p-button-success m-2" icon="pi pi-save">Use Second View</button>
</div>

<p-dialog *ngIf="showSaveDialog" [(visible)]="showSaveDialog" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{ width: '50vw' }"  header="Save New View" [modal]=true>
    <div class="card">
        <div class="grid mt-5">
            <div class="col-6">
                <span class="p-float-label">
                    <input required pInputText id="GlobalViewName" [(ngModel)]="globalViewName" [style]="{ width: '100%' }"/>
                    <label for="GlobalViewName">Global View name</label>
                </span>        
            </div>
            <div class="col-6">
                <span class="p-float-label">
                    <input pInputText id="Alias" [(ngModel)]="alias"  [style]="{ width: '100%' }"/>
                    <label for="Alias">Alias (Optional)</label>
                </span>        
            </div>
            <div class="col-6">
                <span class="p-float-label">
                    <input pInputText id="Hierarchy" [(ngModel)]="hierarchy" [style]="{ width: '100%' }"/>
                    <label for="Hierarchy">Hierarchy (Optional)</label>
                </span>
            </div>
            <div class="col-6">
                <p-dropdown [options]="publishView" [(ngModel)]="selectedPublishView" placeholder="Publish View" [style]="{ width: '100%' }"></p-dropdown>
            </div>
            <div class="col-4" style="text-align: center;">
                <div [style]="{ width: '100%' }">
                    <label>Add To Favorites</label>
                </div>
                <div [style]="{ width: '100%' }">
                    <p-checkbox [(ngModel)]="isFavorite" [binary]="true"></p-checkbox>
                </div>
            </div>
            <div class="col-4"  style="text-align: center;">
                <div [style]="{ width: '100%' }">
                    <label>Private</label>
                </div>
                <div [style]="{ width: '100%' }">
                    <p-checkbox [(ngModel)]="isPrivate" [binary]="true"></p-checkbox>
                </div>
            </div>
            <div class="col-4"  style="text-align: center;">
                <div [style]="{ width: '100%' }">
                    <label>My Default View</label>
                </div>
                <div [style]="{ width: '100%' }">
                    <p-checkbox [(ngModel)]="isDefaultView" [binary]="true"></p-checkbox>
                </div>
            </div>
        </div>
        <div style="text-align: right" class="mt-3">
            <p-footer>
                <button class="p-button-danger p-button-sm m-2 p-button-raised" type="button" pButton icon="pi pi-times" label="Cancel" (click)="closeSaveNewView()"></button>
                <button [disabled]="!globalViewName" class="p-button-success p-button-sm m-2 p-button-raised" type="button" pButton icon="pi pi-check" label="Save" (click)="saveNewView()"></button>
            </p-footer>
        </div>
    </div>
</p-dialog>