import { Component, ElementRef, ViewChild } from '@angular/core';
import { AppConfig, LayoutService } from './service/app.layout.service';
import { InputSwitchChangeEvent } from 'primeng/inputswitch';
import { signOut, fetchUserAttributes } from 'aws-amplify/auth';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
})
export class AppTopbarComponent {
    @ViewChild('menuButton') menuButton!: ElementRef;

    @ViewChild('mobileMenuButton') mobileMenuButton!: ElementRef;

    scales: number[] = [12, 13, 14, 15, 16];
    config!: AppConfig;
    darkMode: boolean = false;
    subscription: any;
    userEmail: string = '';
    firstName: string = '';
    lastName: string = '';

    constructor(public layoutService: LayoutService, public el: ElementRef) {
        this.subscription = this.layoutService.configUpdate$.subscribe(
            (config) => {
                this.config = config;
            }
        );
        let mode = localStorage.getItem('layout-mode');
        if (mode && mode === 'dark') {
            this.darkMode = true;
        }
    }

    async ngOnInit() {
        const userAttributes = await fetchUserAttributes();
        this.userEmail = userAttributes.email!;
        this.firstName = userAttributes.given_name!;
        this.lastName = userAttributes.family_name!;
        this.layoutService.applyDarkModeStyles();
    }

    get scale(): number {
        return this.layoutService.config().scale;
    }    
    set scale(_val: number) {
        this.layoutService.config.update((config) => ({
            ...config,
            scale: _val,
        }));
    }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onDarkModeChange(event: InputSwitchChangeEvent) {
        if(event.checked) {
            //This is dark mode
            this.layoutService.config.update((config) => ({
                ...config,
                colorScheme: 'dark',
                menuTheme: 'dark',
                topbarTheme: 'dark',
            }));
            localStorage.setItem('layout-mode', 'dark');
        }
        else {
            //This is light mode
            this.layoutService.config.update((config) => ({
                ...config,
                colorScheme: 'light',
                menuTheme: 'dim',
                topbarTheme: 'light',
            }));

            localStorage.setItem('layout-mode', 'light');
        }
        this.layoutService.applyDarkModeStyles();
    }

    decrementScale() {
        this.scale--;
    }

    incrementScale() {
        this.scale++;
    }

    async logOut() {
        await signOut();
    }
}
